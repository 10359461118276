.app {

  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-align: center;

  & > .app-header {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    background-color: #282c34;
    font-size: calc(10px + 2vmin);
    color: white;

    & > h1 {
      box-sizing: border-box;
      padding: 1em 0;
      margin: 0;
    }

  }

  & > .app-link {
    color: #61dafb;
  }

}
