
.osake-kauppa-laskuri-view {

  box-sizing: border-box;
  padding: 0;
  margin: 0;

  .button {
    border: 0;
    outline: 0;
    box-sizing: border-box;
    padding: 1em;
    margin: 0;
    color: #ffffff;
    background-color: #282c34;
    text-transform: uppercase;
  }

  .osake-kauppa-laskuri-view-results {

    box-sizing: border-box;
    padding: 0;
    margin: 2em 0 0 0;

    & > .row {

      box-sizing: border-box;
      padding: 0;
      margin: 0;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-items: center;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;

      & > .label {

        box-sizing: border-box;
        padding: 1em;
        margin: 0;
        flex: 1 0 50%;
        text-align: right;
        font-weight: bold;

      }

      & > .value {

        box-sizing: border-box;
        padding: 1em;
        margin: 0;
        flex: 1 0 50%;
        text-align: left;

      }

    }


  }

  form {
    margin-bottom: 4em;
  }

  h3 {
    box-sizing: border-box;
    padding: 1em;
    margin: 0;
    border-bottom: 1px solid #000000;
  }

  & > footer {

    color: #ffffff;
    background-color: #282c34;

    box-sizing: border-box;
    padding: 2em 1em;
    margin: 2em 0 0 0;

    a, a:visited, a:hover, a:active {
      color: #ffffff;
    }

  }

  .checkbox-field {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;

    box-sizing: border-box;
    padding: 1em 0.5em;
    margin: 1px 0;

    background-color: rgba(44, 52, 40, 0.17);

    & > input {
      padding-right: 1em;
    }

  }

}

